import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { MenuItem } from 'primereact/menuitem';
import { locale, addLocale } from 'primereact/api';
import locales from './utils/ru-locale';
import { SystemProvider } from './utils/system-provider';
import Routes from './routes';
import { setupStore } from './store';

// стили сварки
import 'theme/styles/layers.css';
import 'theme/styles/mainStyles.scss';

// иконки пока не имеют самостоятельного слоя, импортируем как и прежде
import '@innouni/ui-kit-styles/outputStyles/icons/primeicons.css';

const store = setupStore();

export type MainMenuItemType = {
    label: string;
    url: string;
    isNested?: boolean;
    disabled?: boolean;
};

export const mainMenuItems: MenuItem[] = [
    {
        label: 'Объекты строительства',
        url: '/gas-pipeline',
        icon: 'pi pi-database',
    },
    {
        label: 'Справочники',
        url: '/dictionaries',
        icon: 'pi pi-database',
    },
];

export const App: React.FC = () => {
    useEffect(() => {
        addLocale('ru', locales.ru);
        locale('ru');
    }, []);
    return (
        <Provider store={store}>
            <BrowserRouter>
                <SystemProvider>
                    <Routes />
                </SystemProvider>
            </BrowserRouter>
        </Provider>
    );
};
